import {getLS, setLS} from 'devegram-javascript-util';
const routeName = 'module->App->UiColorSchema';
const baseUrl = '/xapi/App/UiColorSchema';
const moduleAjaxService = 'backend-service'

const routes = {
    path: 'UiColorSchema',
    name: routeName,
    component: () => import('./View.vue'),
    children: [
      {
        path: 'select-ui-schema',
        name: routeName + '->select-ui-schema',
        component: () => import('./select-ui-schema/View')
      },
      {
        path: 'create',
        name: routeName + '->create',
        component: () => import('./create/View')
      },
      {
        path: 'list',
        name: routeName + '->list',
        component: () => import('./list/View'),
        children: [
          {
            path: ':listPage',
            name: routeName + '->list->page',
            component: () => import('./list/page/View')
          }
        ]
      },
      {
        path: ':entityTitle',
        name: routeName + '->view',
        component: () => import('./view/View.vue')
      }/*,
      {
        path: 'external-component',
        name: routeName + '->external-component',
        component: () => $hks.loadExternalComponent('http://127.6.3.1:8000/xapi/UI/ModuleManager/getComponent/App/UiColorSchema/compName'),
      }*/
    ]
}

export const module = {
  info: {
    routeName,
    name: 'UiColorSchema',
    caption: 'App UiColorSchema',
    namespace: 'App',
    fullName: 'App-UiColorSchema'
    // viewGroup: 'general-modules'
  },
  vuexModules: ['api'],
  sideMenu: {
    notAllowedProfiles: [],
    topSeparator: false,
    caption: 'UiColorSchema',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  },
  router: {
    mainRouteName: 'portal-cpanel',
    routes: routes
  },
  onLoad: async function () {

    // promises.push($xapi.getAppUiColorSchemaDropdown());

    $services['MODULE-App-UiColorSchema'] = {
      api: {
        currentSchema: false,
        getCurrentSchema: function () {
          if (!this.currentSchema) return ''
          return this.currentSchema
        },
        setCurrentSchema: function (schema) {
          setLS('MODULE-App-UiColorSchema', schema)
          $store.state['App-UiColorSchema-api'].currentSchemaClasses = schema.cssClasses
          this.currentSchema = schema
        },
      },
      schemas: {
        schema_0: {
          name: 'None',
          cssClasses: ''
        },
        schema_1: {
          name: 'Schema 1',
          cssClasses: 'appUiSchema1'
        },
        schema_2: {
          name: 'Schema 2',
          cssClasses: 'appUiSchema2'
        },
        schema_3: {
          name: 'Schema 3',
          cssClasses: 'appUiSchema3'
        },
        schema_4: {
          name: 'Schema 4',
          cssClasses: 'appUiSchema4'
        },
        schema_5: {
          name: 'Schema 5',
          cssClasses: 'appUiSchema5'
        },
        schema_6: {
          name: 'Schema 6',
          cssClasses: 'appUiSchema6'
        }
      }
    }

    let lastUsedSchema = getLS('MODULE-App-UiColorSchema')
    if (lastUsedSchema && lastUsedSchema != '') $services['MODULE-App-UiColorSchema'].api.setCurrentSchema(lastUsedSchema)

    return 0}
}

export default module
